<template>
  <nav class="bg-lexmea-gray-500 pt-2">
    <ul class="flex list-none items-stretch">
      <li v-for="(item, index) of navItems" :key="index" class="w-full">
        <a
          class="flex h-full flex-col items-center border-b-2 pb-1 transition"
          :class="
            activeItem === index
              ? 'border-b-4 border-lexmea-blue-400 text-lexmea-blue-400'
              : 'pb-[6px] text-gray-500 hover:text-lexmea-blue-400/80'
          "
          :href="item.href"
        >
          <component
            :is="item.icon"
            :selected="activeItem === index"
            class="mx-auto h-8 w-8 fill-current stroke-current"
            :class="{ 'stroke-2': activeItem === index }"
          />
          <span class="text-center text-sm font-bold">
            {{ item.title }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
interface Props {
  activeItem: number;
  navItems: {
    href: string;
    icon: any;
    title: string;
  }[];
}

defineProps<Props>();
</script>
